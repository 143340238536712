@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('./helvetica-neue-5/HelveticaNeueBlack.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
}

img {
  max-width: 100%;
}

h1 {
  font-size: 36px;
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'HelveticaNeue';
}

p, li, a {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

html {
  scroll-behavior: smooth;
}






