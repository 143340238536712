.event__title h1 {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #1F1F1F;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
}

.event-carousel {
    width: 100%;
    max-width: 40%;
    margin: 0 auto;
    padding: 100px 20px;
}

.carousel .carousel-slider {
    height: auto;
}

.carousel .control-arrow {
    opacity: 0.5;
}

.carousel .control-arrow:hover {
    opacity: 1;
}

.carousel .carousel-status {
    display: none;
}

.carousel .carousel .slide img {
    width: 100%;
    height: auto;
    max-height: 500px;
    object-fit: cover;
}

.carousel-caption {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    border-radius: 5px;
    text-align: left;
}

.carousel-caption h3 {
    margin: 0;
    font-size: 24px;
}

.carousel-caption p {
    margin: 5px 0 0;
    font-size: 16px;
}

@media (max-width: 768px) {
    .carousel-caption {
        font-size: 14px;
        padding: 5px;
    }

    .carousel-caption h3 {
        font-size: 18px;
    }

    .carousel-caption p {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .event__title h1 {
        font-size: 24px;
    }
 
    .event-carousel {
        /* padding: 20px; */
        max-width: 100% !important;
        width: 90%;
    }

    .carousel .carousel .slide img {
        width: 100% !important;
        max-height: 100%;
    }

    .carousel-caption {
        bottom: 10px;
        left: 10px;
        right: 10px;
        padding: 5px;
    }

    .carousel-caption h3 {
        font-size: 16px;
    }

    .carousel-caption p {
        font-size: 10px;
    }
}
