/* HeroSection.css */
.hero-container {
    position: relative;
    /* background-image: url('/assets/hero-bg.jpeg'); */
    background-size: cover;
    background-position: center;
    color: #fff;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
  
.hero-container::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); /* For a solid overlay; not needed if using gradient */
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    z-index: 1; /* Ensure it's above the background image but below the content */
}

.hero-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    transition: background-color 0.3s ease;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    align-items: center;
    z-index: 1000;
}

.hero-nav.scrolled {
    background-color: rgba(0, 0, 0, 0.7); /* Or any other color you prefer */
  }
  
.logo {
    max-width: 150px;
    z-index: 1;
}
  
.nav-menu {
    list-style: none;
    display: flex;
    gap: 20px;
    padding: 20px;
    z-index: 1;
    transition: transform 0.5s ease;
}
  
.nav-menu li a {
    text-decoration: none;
    color: #fff;
}

.nav-menu li a:hover {
    color: #02132d;
}
  
.hero-content {
    position: relative;
    z-index: 1;
    max-width: 850px;
    padding: 20px;
}
  
.hero-content h1 {
    margin-bottom: 20px;
    line-height: 40px;
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    color: #FFFFFF;
}
  
.hero-content p {
    margin-bottom: 20px;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.hero-content button {
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    background-color: #1D3F6F;
    border-radius: 4px;
    border: 0px;
    color: #FFFFFF;
    cursor: pointer;
    font-family: 'Roboto Condensed';
}

.hero-content button:hover {
    background-color: #031f46;
}

.menu-icon {
    display: none; /* Hidden by default */
    cursor: pointer;
    z-index: 1000;
}

.nav-menu.open {
    transform: translateX(0);
}

/* Animation for hero content */
.hero-heading, .hero-subheading, .hero-cta {
    animation: fadeInUp 1s ease-out forwards;
    opacity: 0;
}

.hero-heading { animation-delay: 0.5s; }
.hero-subheading { animation-delay: 1s; }
.hero-cta { animation-delay: 1.5s; }

.hero-cta a { 
    color: #fff
}

@keyframes fadeInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

  
  /* Responsive */
  @media (max-width: 768px) {
    .nav-menu {
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.9);
        position: fixed;
        justify-content: center;
        right: 0;
        top: 0;
        height: 100vh;
        padding: 20px;
        border-radius: 5px;
        width: 100%; /* Adjust based on preference */
        transform: translateX(100%);
        transition: transform 0.5s ease;
    }

    .menu-icon {
        display: block; /* Only shown in mobile view */
    }

    .nav-menu li a {
        color: #000;
    }
  
    .hero-content {
      width: 90%;
    }

    .menu-icon {
        display: block;
    }

    .nav-menu.open {
        transform: translateX(0);
    }
    
    .nav-menu li a {
        color: #000;
    }

    .hero-content h1 {
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;

    }
}
