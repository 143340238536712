.after-school-programs {
    text-align: center;
    padding: 100px 20px;
    background-color: #F5F5F5;
  }
  
  .after-school-programs h2,
  .after-school-programs p {
    margin-bottom: 20px;
  }

.after-school-programs h2 {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #1F1F1F;
}

.after-school-programs p {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #84888B;
    width: 60%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}
  
.images-row {
    display: flex;
    margin-bottom: 20px;
    overflow-x: auto; /* Enables horizontal scrolling on mobile */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS devices */
}
  
  .images-row img {
    flex: 0 0 auto; /* Prevents the images from being squashed */
    max-width: 100%;
    max-height: 300px; /* Adjust this value based on your needs */
    height: auto;
    width: auto; /* Ensures the width of the image is based on its aspect ratio */
    flex-grow: 1; /* Allows the image to grow based on the flex value set inline */
    animation: zoomIn 0.5s ease-out forwards; /* Apply the zoom animation */
  }

  /* First row specific styles */
.images-row:first-of-type img:first-child {
    width: 70%; /* First image takes up 70% of the row */
  }
  
  .images-row:first-of-type img:last-child {
    width: 30%; /* Second image takes up 30% of the row */
  }
  
  /* Second row specific styles */
  .images-row:last-of-type img:first-child {
    width: 30%; /* First image takes up 30% of the row */
  }
  
  .images-row:last-of-type img:last-child {
    width: 70%; /* Second image takes up 70% of the row */
  }
  
  /* This ensures there's no gap between images */
  .images-row img:not(:last-child) {
    margin-right: 10px;
  }
  
  /* Second row specific styles if needed */
  .second-row img:not(:first-child) {
    margin-left: 10px;
  }

  @keyframes zoomIn {
    from {
      transform: scale(0.8);
      opacity: 0.5;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  
  @media (min-width: 768px) {
    /* Disable horizontal scrolling for desktop */
    .images-row {
      overflow-x: hidden;
    }

    .images-row img {
        width: auto; /* Allows the image to have a natural width based on its aspect ratio */
        max-height: none;
      }
    
      /* Adjusting image widths for desktop to maintain the 70%/30% and 30%/70% layout */
      .images-row:first-of-type img:first-child,
      .images-row:last-of-type img:last-child {
        width: 70%;
      }
    
      .images-row:first-of-type img:last-child,
      .images-row:last-of-type img:first-child {
        width: 30%;
      }
  }

  @media (max-width: 460px) {
    .after-school-programs p {
        width: 100%;
        font-size: 16px;
    }

    .after-school-programs h2 {
        font-size: 24px;
    }
  }
  