.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E8F0F7;
  background-size: cover;
  background-position: center right;
  padding: 100px 10px;
}

.about-text {
  flex: 1;
  padding: 20px;
}

.about-text h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 110%;
  color: #1F1F1F;
  margin-bottom: 30px;
}

.about-text p {
  padding: 0px;
  gap: 24px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.about-image img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
}

/* Animations */
@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Adjust the animation classes */
.slide-in-left {
  animation: slideInLeft 1s ease-out forwards;
}

.fade-in {
  animation: fadeIn 2s ease-out forwards;
}

/* Responsive */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    text-align: center;
  }

  .about-image img {
    max-width: 80%;
    margin-top: 30px;
  }

  .about-text h1 {
    font-size: 24px;
  }

  .about-text p {
    font-size: 14px;
  }
}
