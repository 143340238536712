.mission-section {
    background-color: #E8F0F7;
    padding: 200px 20px; /* Reduced padding for better mobile responsiveness */
    position: relative;
    text-align: left;
  }
  
  .mission-subheading {
    color: #333; /* Adjust color as needed */
    margin-bottom: 20px;
    text-align: left !important;
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    color: #1D3F6F;
  }
  
  .mission-paragraph {
    color: #666; /* Adjust color as needed */
    text-align: left;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 110%;
    color: #1F1F1F;
    width: 75%;
  }

  .mission-pad {
    margin-left: 40px;
  }
  

  
  
  /* Media Queries for additional responsiveness */
  @media (max-width: 768px) {
    .mission-subheading {
      font-size: 24px; /* Smaller font size on smaller screens */
    }
  
    .mission-paragraph {
      font-size: 28px; /* Smaller font size on smaller screens */
      max-width: 100%;
      line-height: 110%; 
      width: 95%;
    }
  
    .mission-section::after {
      width: 50%; 
      max-width: 150px; 
    }

    .mission-pad {
        margin-left: 0px;
      }
  }
  