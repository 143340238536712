.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #2e4a71;
    color: white;
    width: 40px; /* Square shape */
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px; /* Optional, for rounded corners */
    font-size: 24px; /* Adjust based on your preference */
    z-index: 1000; /* Ensure it's above most elements */
  }
  